import {useState} from 'react';
import Link from 'next/link';
import {useRouter} from 'next/navigation';
import {Customer} from '@delorand/db/schema/customer';
import {InstagramSvg, XSvg} from '@delorand/ui/src/svg/logo';
import {upperFirst} from '@delorand/utils/shared';
import {ChevronDownIcon, ChevronRightIcon} from '@heroicons/react/24/solid';
import {Content, Header, Item, Root, Trigger} from '@radix-ui/react-accordion';
import {useStore} from 'c/store/store';
import ScreenSwitch from '../../screen-switch';
import Collapse from './collapse';

interface Props {
  isOpen: boolean;
  user: Customer | null | undefined;
  onClose: () => void;
}

const CollapseMenu = ({isOpen, user, onClose}: Props) => {
  const router = useRouter();

  const categories = useStore(state => state.categories);

  const [page, setPage] = useState('shop');

  return (
    <Collapse open={isOpen} setOpen={onClose}>
      <ScreenSwitch
        className="mt-3"
        active={page}
        onSwitch={setPage}
        screens={[
          {id: 'shop', name: 'SHOP'},
          {
            id: 'account',
            name: 'ACCOUNT',
          },
        ]}
      />

      {page === 'shop' ? (
        <div className="w-full flex-1 overflow-y-auto">
          <h4 className="my-3 text-center font-medium">OUR CATEGORIES</h4>

          <Root type="single" collapsible className="mb-3">
            {categories.map(category => (
              <Item key={category.id} value={category.slug}>
                <Header className="flex w-full">
                  <Trigger className="AccordionTrigger text-custom-neutral flex w-full justify-between border-b bg-black/10 p-3 text-sm font-semibold uppercase">
                    <span>{upperFirst(category.slug)}</span>
                    <ChevronDownIcon width={20} />
                  </Trigger>
                </Header>
                <Content className="AccordionContent overflow-hidden bg-black pt-2">
                  <div className="grid grid-cols-2 gap-4 px-2 py-2">
                    {category.children.map(category => (
                      <div key={category.id} className="px-3">
                        <Link
                          href={`/category/${category.slug
                            .split('/')
                            .join('&')
                            .toLowerCase()}`}
                          className="text-start text-sm font-bold text-stone-300 uppercase"
                        >
                          {category.name}
                        </Link>
                        <ul className="mt-1 flex flex-col">
                          {category.children.map(category => (
                            <li
                              key={category.slug}
                              onClick={() => {
                                router.push(
                                  `/category/${category.slug
                                    .split('/')
                                    .join('&')
                                    .toLowerCase()}`
                                );
                                onClose();
                              }}
                              className={`py-1 text-start text-base uppercase underline`}
                            >
                              {category.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                </Content>
              </Item>
            ))}
          </Root>
        </div>
      ) : (
        <div className="w-full flex-1 overflow-y-auto pt-5">
          <ul className="flex flex-col gap-2 p-1">
            {user ? (
              <LinkItem onClose={onClose} href="/account" text="ACCOUNT" />
            ) : (
              <LinkItem
                onClick={() => {
                  router.push('/login');
                  onClose();
                }}
                text="SIGN-IN"
              />
            )}
            <LinkItem onClose={onClose} href="/orders" text="ORDERS" />
            <LinkItem
              text="COUPONS"
              href="/account/coupons"
              onClose={onClose}
            />
            {/* <LinkItem onClose={onClose} text='CART' href='/cart' /> */}
          </ul>
          <div className="my-2 rounded-xl border-b border-b-neutral-600" />
          <ul className="flex flex-col gap-2 p-1">
            <LinkItem onClose={onClose} text="HELP CENTER" href="/help" />
            <LinkItem onClose={onClose} text="CONTACT US" href="/contact" />
          </ul>
        </div>
      )}

      <div className="space-y-4 p-3">
        <div className="flex items-center gap-4">
          <div className="flex flex-1 gap-3 text-sm">
            <Link prefetch={false} className="" href={'/help'}>
              Help
            </Link>
            <Link prefetch={false} className="" href={'/contact'}>
              Contact Us
            </Link>
          </div>
          <a
            target="_blank"
            rel="noreferrer"
            href="http://www.instagram.com/delorandhq"
          >
            <InstagramSvg width={30} height={30} />
          </a>

          {/* <a
              target='_blank'
              rel='noreferrer'
              href='http://www.facebook.com/delorandhq'>
              <FacebookSvg width={28} height={28} />
            </a> */}

          <a
            target="_blank"
            rel="noreferrer"
            href="http://www.twitter.com/delorandhq"
          >
            <XSvg width={30} height={30} />
          </a>
        </div>
        <div className="text-custom-white flex justify-between text-sm font-medium">
          <Link prefetch={false} className="" href={'/privacy-policy'}>
            Privacy Policy
          </Link>
          <Link
            prefetch={false}
            className=""
            href={'/privacy-policy?active=cookies'}
          >
            Cookies
          </Link>
          <Link prefetch={false} className="" href={'/terms-and-conditions'}>
            Terms and Conditions
          </Link>
        </div>
      </div>
    </Collapse>
  );
};

const LinkItem = ({
  text,
  href,
  onClose,
  className,
  onClick,
}: {
  text: string;
  href?: string;
  onClose?: () => void;
  className?: string;
  onClick?: () => void;
}) => {
  const router = useRouter();
  return (
    <li
      onClick={() => {
        if (onClick) {
          onClick();
        } else if (onClose && href) {
          router.push(href);
          onClose();
        }
      }}
      className={`flex justify-between p-2 text-start ${className || ''}`}
    >
      <span>{text}</span>
      <ChevronRightIcon width={18} color="white" />
    </li>
  );
};

export default CollapseMenu;
