import {type ReactNode} from 'react';
import {cn} from '@delorand/utils/cn';
import {useScreenSize} from '@delorand/utils/screen';
import * as Collapsible from '@radix-ui/react-collapsible';
import * as Dialog from '@radix-ui/react-dialog';
import {RemoveScroll} from 'react-remove-scroll';

export interface CollapseProps {
  open: boolean;
  children: ReactNode;
  setOpen: (state: boolean) => void;
}
const Collapse = ({open, children, setOpen}: CollapseProps) => {
  const isSmall = useScreenSize();

  return (
    <>
      {!isSmall ? (
        <Dialog.Root open={open} onOpenChange={setOpen}>
          <Dialog.Content
            className={cn(
              'absolute top-16 left-10 w-[35rem] rounded-lg border',
              'p-2 transition-all duration-200'
            )}
          >
            {children}
          </Dialog.Content>
        </Dialog.Root>
      ) : (
        <Collapsible.Root open={open} className="md:hidden">
          <Collapsible.Content className="CollapsibleContent w-full max-w-lg bg-transparent md:mt-1 md:ml-20">
            <RemoveScroll className="flex h-[530px] w-full flex-col gap-2 px-1 pt-2 drop-shadow-xl md:rounded-lg md:border">
              {children}
            </RemoveScroll>
          </Collapsible.Content>
        </Collapsible.Root>
      )}
    </>
  );
};

export default Collapse;
