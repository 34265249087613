'use client';

import type {ReactNode} from 'react';
import {useState} from 'react';
import Link from 'next/link';
import {usePathname, useRouter} from 'next/navigation';
import Avatar from '@delorand/ui/src/radix/avatar';
import {Bars2Svg} from '@delorand/ui/src/svg/header';
import {CartSvg} from '@delorand/ui/src/svg/layout';
import {useStore} from 'c/store/store';
import {useUser} from 'c/utils/hooks';
import {useCart} from '../../../utils/cart';
import CollapseMenu from './collapse-menu';

interface HeaderProps {
  children?: ReactNode;
}

const Header = ({children}: HeaderProps) => {
  const router = useRouter();
  const [open, setOpen] = useState(false);

  const [user] = useUser();

  const {cartItems} = useCart();

  const cartActive = useStore(state => state.cartPath);

  const groupCartCount = 0;

  const pathname = usePathname();

  return (
    <div className="relative flex w-full flex-col bg-black/90 p-2 backdrop-blur-lg md:flex-row">
      <div className="mx-auto flex w-full max-w-(--breakpoint-xl) items-center justify-between">
        <button
          className="ml-2 text-white"
          onClick={() => setOpen(state => !state)}
        >
          <Bars2Svg />
        </button>
        <div className="md:hidden" />
        <div className="flex items-center md:ml-10 md:gap-20">
          <Link
            href="/"
            className="absolute left-1/2 -translate-x-1/2 text-xl font-medium text-white md:static md:left-auto md:-translate-x-0"
          >
            delorand
          </Link>
        </div>

        <div className="hidden md:block">
          <div className="w-[50rem]" />
          <div className="center-x center-y absolute hidden w-full max-w-lg md:block">
            {children}
          </div>
        </div>

        <div className="flex items-center gap-4 pr-2 md:gap-4 lg:pr-3">
          <button
            className={`flex items-center hover:opacity-75`}
            onClick={() =>
              router.push(user ? '/account' : `/login?callbackUrl=${pathname}`)
            }
          >
            <Avatar
              src={user?.image}
              className="size-[27px] rounded-2xl"
              fallback={user?.firstName}
            />
          </button>
          <Link
            href={
              cartActive === 'cart'
                ? `/cart?active=cart`
                : cartActive === 'group'
                  ? `/cart?active=group`
                  : `/cart/${cartActive}`
            }
            className="relative rounded-lg p-1 hover:opacity-75"
            aria-label="cart"
          >
            <CartSvg />

            {groupCartCount > 0 && (
              <p className="absolute bottom-0 -left-[3px] flex h-[18px] w-[18px] flex-col items-center justify-center rounded-full bg-blue-500 text-center text-xs font-semibold tracking-[-0.05rem] text-white">
                {groupCartCount}
              </p>
            )}

            {cartItems.length > 0 && (
              <p className="bg-og-red absolute top-0 -right-[3px] flex h-[20px] w-[20px] flex-col items-center justify-center rounded-full text-center text-[13px] font-semibold tracking-[-0.05rem] text-white">
                {cartItems.length < 101 ? cartItems.length : '100+'}
              </p>
            )}
          </Link>

          <Link
            prefetch={false}
            href="/help"
            className="text-custom-white hidden text-sm font-medium hover:opacity-75 md:block"
          >
            help
          </Link>
        </div>
      </div>

      <CollapseMenu
        isOpen={open}
        user={user}
        onClose={() => setOpen(state => !state)}
      />
      <div className="md:hidden">
        {children ?? (open && <div className="border-b-2 border-b-white/50" />)}
      </div>
    </div>
  );
};

export default Header;
