'use client';

import {useRouter} from 'next/navigation';

interface Props {
  screens: {name: string; id: string}[];
  onSwitch?: (id: string) => void;
  active: string;
  className?: string;
  disabled?: boolean;
  href?: string;
}

function ScreenSwitch({
  onSwitch,
  screens,
  active,
  className,
  disabled,
  href = '',
}: Props) {
  const router = useRouter();
  return (
    <div className={`flex ${className}`}>
      {screens.map(screen => (
        <button
          disabled={disabled}
          key={screen.id}
          onClick={() =>
            onSwitch
              ? onSwitch(screen.id)
              : router.replace(`${href}?active=${screen.id}`)
          }
          className={`w-full text-center font-semibold`}
        >
          <span>{screen.name}</span>
          <div
            className={`mt-0.5 h-[2px] w-full rounded-xs ${active === screen.id ? 'bg-custom-white' : ''}`}
          />
        </button>
      ))}
    </div>
  );
}

export default ScreenSwitch;
