export const Bars2Svg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="11"
      viewBox="0 0 25 11"
      fill="none"
    >
      <rect y="8" width="23" height="2" fill="#D9D9D9" />
      <rect width="23" height="2" fill="#D9D9D9" />
    </svg>
  );
};
